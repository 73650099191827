import { Maybe } from 'lunr-core/browser'
import React, { ReactNode } from 'react'
import { BrowserConfiguration, getBrowserConfiguration } from '../../BrowserConfiguration'
import { GoogleTagManagerBodyScripts } from '../google-tag-manager'
import PageHead from '../page-head'

interface Props {
  children?: Maybe<ReactNode>
}

interface State {

}

export default class Layout extends React.Component<Props, State> {
  render = () => {
    const config:BrowserConfiguration = getBrowserConfiguration()
    return (
      <React.Fragment>
        <PageHead />

        {this.props.children}

        <GoogleTagManagerBodyScripts container={config.GOOGLE_TAG_MANAGER_CONTAINER!} />
      </React.Fragment>
    )
  }
}
import { initializeApp } from 'firebase/app'
import { Auth, createUserWithEmailAndPassword, getAuth as firebaseGetAuth, sendPasswordResetEmail as firebaseSendPasswordResetEmail, signInWithEmailAndPassword as firebaseSignInWithEmailAndPassword, onAuthStateChanged, sendEmailVerification, updateProfile, User, UserCredential } from 'firebase/auth'
import { axios, AxiosResponse, Maybe } from 'lunr-core/browser'
// @ts-ignore No definition file for the `mitt` package
import mitt, { Emitter } from 'mitt'
import { BrowserConfiguration, getBrowserConfiguration } from '../BrowserConfiguration'
import Routes from '../Routes'

const config:BrowserConfiguration = getBrowserConfiguration()
const app = initializeApp({
  apiKey: config.FIREBASE_API_KEY,
  authDomain: config.FIREBASE_AUTH_DOMAIN,
  projectId: config.FIREBASE_PROJECT_ID,
  storageBucket: config.FIREBASE_STORAGE_BUCKET,
  messagingSenderId: config.FIREBASE_MESSAGING_SENDER_ID,
  appId: config.FIREBASE_APP_ID,
  measurementId: config.FIREBASE_MEASUREMENT_ID
})
const auth = firebaseGetAuth(app)

export const SESSION_COOKIE_NAME:string = 'session'

// <observer>
export type OnSessionChangedHandler = (token:string|null) => void
const SESSION_CHANGED_EVENT:string = 'sessionChanged'
const _emitter:Emitter<any> = mitt<any>()
onAuthStateChanged(auth, (user:User|null) => {
  _emitter.emit(SESSION_CHANGED_EVENT, user)
})

export const addSessionListener = (callback:OnSessionChangedHandler):void => {
  _emitter.on(SESSION_CHANGED_EVENT, callback)
}

export const removeSessionListener = (callback:OnSessionChangedHandler):void => {
  _emitter.off(SESSION_CHANGED_EVENT, callback)
}
// </observer>

/**
 * A convenience function to get the Firebase auth object without having to re-instantiate it every time.
 */
export const getAuth = ():Auth => { return auth }

export const isLoggedIn = ():boolean => {
  return !!auth.currentUser
}

export const getCurrentUser = ():User|null => {
  return auth.currentUser
}

export const getCurrentUserToken = async ():Promise<string|null> => {
  const user = getCurrentUser()
  if (!user) {
    return null
  }
  
  const token:string|null = await user.getIdToken()
  return token
}

/**
 * A convenience function to sign in with email and password 
 * without having to instantiate the Firebase auth object manually.
 * 
 * Returns the idToken if successful.
 */
export const signInWithEmailAndPassword = async (email:string, password:string):Promise<Maybe<string>> => {
  if (!email) {
    throw new Error('email is required')
  }

  if (!password) {
    throw new Error('password is required')
  }

  let userCredential:Maybe<UserCredential> = null
  try {
    userCredential = await firebaseSignInWithEmailAndPassword(getAuth(), email, password)
  }
  catch(e) {
    console.warn('Unable to sign in ' + JSON.stringify(e))
    return null
  }

  if (!userCredential || !userCredential.user) {
    return null
  }

  let idToken:Maybe<string> = null
  try {
    idToken = await userCredential.user.getIdToken()
  }
  catch (e) {
    console.warn('Unable to obtain id token! ' + JSON.stringify(e))
    return null
  }

  if (!idToken) {
    return null
  }

  return idToken
}

export interface CreateFirebaseUserInput {
  email: string
  password: string
  firstName: string
  lastName: string
}

export interface CreateFirebaseUserOutput {
  idToken?: string
  error?: string
}

export const createFirebaseUserAccount = async (input:CreateFirebaseUserInput):Promise<CreateFirebaseUserOutput> => {
  if (!input) {
    throw new Error('input is required')
  }

  const GENERIC_CREATE_ERROR:CreateFirebaseUserOutput = { error: 'Unable to create user account' }

  let userCredential:Maybe<UserCredential> = null
  try {
    userCredential = await createUserWithEmailAndPassword(getAuth(), input.email, input.password)
  }
  catch(e:any) {
    console.warn('Unable to create user account! ' + JSON.stringify(e))
    if (e.code === 'auth/email-already-in-use') {
      return { error: 'Email address is already in use' }
    }

    return GENERIC_CREATE_ERROR
  }

  try {
    const displayName:string = input.firstName + ' ' + input.lastName
    await updateProfile(userCredential.user, { displayName:displayName })
  }
  catch(e) {
    console.warn('Unable to set user display name! ' + JSON.stringify(e))
    return GENERIC_CREATE_ERROR
  }

  // Unfortunately, getIdToken() does NOT include the updated displayName, so we have to LOG IN AGAIN to get the updated token.
  try {
    userCredential = await firebaseSignInWithEmailAndPassword(getAuth(), input.email, input.password)
  }
  catch(e) {
    console.warn('Error signing in with updated profile ' + JSON.stringify(e))
    return GENERIC_CREATE_ERROR
  }

  if (!userCredential) {
    console.warn('Unable to sign in with updated profile')
    return GENERIC_CREATE_ERROR
  }

  const config:BrowserConfiguration = getBrowserConfiguration()
  if (config.FIREBASE_EMAIL_VERIFICATION_REQUIRED) {
    try {
      await sendEmailVerification(userCredential.user, { url: config.SITE_URL })
      console.log('send email verification')
    }
    catch(e) {
      console.log('unable to send verification email')
    }
  }

  let idToken:Maybe<string> = null
  try {
    idToken = await userCredential.user.getIdToken()
  }
  catch (e) {
    console.warn('Unable to obtain id token! ' + JSON.stringify(e))
    return GENERIC_CREATE_ERROR
  }

  return {
    idToken: idToken
  }
}

export const sendPasswordResetEmail = async (email:string):Promise<boolean> => {
  const config:BrowserConfiguration = getBrowserConfiguration()
  try {
    await firebaseSendPasswordResetEmail(getAuth(), email, { url: config.SITE_URL })
  }
  catch(e) {
    console.warn('Unable to send reset password email! ' + JSON.stringify(e))
    return false
  }
  
  return true
}

export const signOutAccount = async () => {
  const success:boolean = await clearSessionCookie()
  if (success) {
    window.location.href = Routes.LOGIN
  }
}

const clearSessionCookie = async ():Promise<boolean> => {
  const config:BrowserConfiguration = getBrowserConfiguration()
  let response:Maybe<AxiosResponse> = null
  try {
    response = await axios.post('/api/delete-session-cookie', {}, {
      headers: {
        'apiKey': config.API_KEY
      }
    })
  }
  catch(e) {
    console.warn('Unable to delete session cookie! ' + JSON.stringify(e))
    return false
  }

  if (!response || response.status !== 200) {
    console.warn('Session cookie endpoint did not return a 200 response')
    return false
  }

  return true
}
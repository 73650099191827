import cx from 'classnames'
import { Formik, FormikHelpers, FormikProps } from 'formik'
import { AnimatedEllipsis, isValidEmailAddress } from 'lunr-core/browser'
import React, { useState } from 'react'
import StringDictionary from '../../../library/utils/StringDictionary'
import { sendPasswordResetEmail } from '../../firebase'

interface FormProps {
  email: string
  submit: string
}

interface Props {
  onBackSelected: () => any
}

export default (props:Props) => {
  const [resetCompleted, setResetCompleted] = useState<boolean>(false)

  const onSubmit = async (values:FormProps, helpers:FormikHelpers<FormProps>) => {
    helpers.setSubmitting(true)
    const success:boolean = await sendPasswordResetEmail(values.email)
    helpers.setSubmitting(false)
    if (!success) {
      helpers.setFieldError('submit', 'Unable to reset password')
      return
    }

    helpers.resetForm()
    setResetCompleted(true)
  }

  return (
    <Formik
      initialValues={{
        email: '',
        submit: ''
      }}
      validate={validateInput}
      onSubmit={onSubmit}
    >
      {(helpers:FormikProps<FormProps>) => {
        if (resetCompleted) {
            return (
              <React.Fragment>
                <h1>Forgot Password?</h1>
                <p>Check your inbox to reset your password.</p>
                <button type='button' className='btn--chromeless' onClick={props.onBackSelected}>
                  Back to Login
                </button>
              </React.Fragment>
            )
        }
        
        return (
          <form onSubmit={helpers.handleSubmit}>
            <h1>Forgot Password?</h1>
            <p>Enter the email address associated with your account.</p>
            <div className={cx('form-group--tight', helpers.touched.email && helpers.errors.email && 'has-error')}>
              <label htmlFor='email' className='visuallyhidden'>Email Address</label>
              <input
                type='email'
                id='email'
                name='email'
                className='form-control'
                placeholder='Email Address'
                maxLength={255}
                onChange={helpers.handleChange}
                onBlur={helpers.handleBlur}
                value={helpers.values.email}
                disabled={helpers.isSubmitting}
              />
              {helpers.errors.email && (
                <p className='error'>{helpers.errors.email}</p>
              )}
            </div>
                    
            {helpers.errors.submit && (
              <p className='error'>{helpers.errors.submit}</p>
            )}
            
            <div className='btn-row'>
              <button type='button' className='btn--chromeless' onClick={props.onBackSelected}>
                Back to Login
              </button>
              <button type='submit' className='btn landmark' disabled={helpers.isSubmitting}>
                {helpers.isSubmitting ? <AnimatedEllipsis /> : 'Submit' }
              </button>
            </div>
          </form>
        )
      }}
    </Formik>
  )
}

const validateInput = (values:FormProps):StringDictionary => {
  const errors:StringDictionary = {}
  
  if (!isValidEmailAddress(values.email)) {
    errors.email = 'Invalid email address'
  }

  return errors
}
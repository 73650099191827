import { Maybe } from 'lunr-core/browser'
import { signInWithEmailAndPassword } from '../firebase'
import createSessionCookie from './createSessionCookie'

export interface SignInInput {
  email: string
  password: string
}

/**
 * The flow for signing in. Uses Firebase to sign in with the supplied
 * credentials. Then fetches the session cookie so that subsequent API
 * calls can be made.
 * 
 * Returns true if the flow finished successfully, false otherwise.
 */
const signInFlow = async (input:SignInInput):Promise<boolean> => {
  let idToken:Maybe<string> = null
  try {
    idToken = await signInWithEmailAndPassword(input.email, input.password)
  }
  catch(e) {
    console.error('Failed to sign in! err=' + e)
  }
  
  if (!idToken) {
    return false
  }

  const success:boolean = await createSessionCookie(idToken)
  if (!success) {
    return false
  }

  return true
}

export default signInFlow
import { AxiosResponse, Maybe, axios } from 'lunr-core/browser'
import { BrowserConfiguration, getBrowserConfiguration } from '../BrowserConfiguration'

const createSessionCookie = async (idToken:string):Promise<boolean> => {
  const config:BrowserConfiguration = getBrowserConfiguration()
  let response:Maybe<AxiosResponse> = null
  try {
    response = await axios.post(
      '/api/create-session-cookie',
      {
        idToken: idToken
      },
      {
        headers: {
          'apiKey': config.API_KEY
        }
      }
    )
  }
  catch(e) {
    console.warn('Unable to obtain session cookie! ' + JSON.stringify(e))
    return false
  }

  if (!response || response.status !== 200) {
    console.warn('Session cookie endpoint did not return a 200 response')
    return false
  }

  return true
}

export default createSessionCookie
import cx from 'classnames'
import { Formik, FormikHelpers, FormikProps } from 'formik'
import { AnimatedEllipsis, CompanyStatus, GetBorrowerDetailsData, Maybe, isValidEmailAddress } from 'lunr-core/browser'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { useState } from 'react'
import Routes from '../library/Routes'
import Layout from '../library/components/layout'
import Logo from '../library/components/logo'
import ResetPassword from '../library/components/password-reset-form'
import { getMessagingAPIForBrowser } from '../library/getMessagingAPIForBrowser'
import StringDictionary from '../library/utils/StringDictionary'
import signInFlow from '../library/utils/signInFlow'

interface FormProps {
  email: string
  password: string
  submit: string
}

export default () => {
  const router = useRouter()
  const [showResetPassword, setShowResetPassword] = useState(false)
  
  const onSubmit = async (values:FormProps, helpers:FormikHelpers<FormProps>) => {
    helpers.setSubmitting(true)

    const success:boolean = await signInFlow({
      email: values.email,
      password: values.password
    })
    if (!success) {
      helpers.setSubmitting(false)
      helpers.setFieldError('submit', 'Invalid email or password')
      return
    }

    const response:Maybe<GetBorrowerDetailsData> = await getMessagingAPIForBrowser().getBorrowerDetails()
    if (!response) {
      console.warn('Failed to get account details.')
      helpers.setSubmitting(false)
      helpers.resetForm()
      return
    }

    const redirectUrl:string = getUserLandingPage(response.status)
    router.push(redirectUrl)
  }
  
  return (
    <Layout>
      <div className='signin'>
        <header className='HeaderSignin'>
          <div className='container'>
            <div className='HeaderSignin-logo'>
              <Logo />
            </div>
          </div>
        </header>
        <div className='gw gw--full'>
          <div className='g lap-one-half full-height'>
            <div className='container container--inset'>

            {showResetPassword && 
              <ResetPassword
                onBackSelected={() => { setShowResetPassword(false) }} 
              />
            }

            {!showResetPassword && (
                <Formik
                  initialValues={{
                    email: '',
                    password: '',
                    submit: ''
                  }}
                  validate={validateInput}
                  onSubmit={onSubmit}
                >
                  {(helpers:FormikProps<FormProps>) => {
                    return (
                      <form onSubmit={helpers.handleSubmit}>
                        <h1>Login</h1>
                        <div className={cx('form-group--tight', helpers.touched.email && helpers.errors.email && 'has-error')}>
                          <label htmlFor='email' className='visuallyhidden'>Email Address</label>
                          <input
                            type='email'
                            id='email'
                            name='email'
                            className='form-control'
                            placeholder='Email Address'
                            maxLength={255}
                            onChange={helpers.handleChange}
                            onBlur={helpers.handleBlur}
                            value={helpers.values.email}
                            disabled={helpers.isSubmitting}
                          />
                          {helpers.touched.email && helpers.errors.email && (
                            <p className='error'>{helpers.errors.email}</p>
                          )}
                        </div>
                        <div className={cx('form-group--tight', helpers.touched.password && helpers.errors.password && 'has-error')}>
                          <label htmlFor='' className='visuallyhidden'>Password</label>
                          <input
                            type='password'
                            id='password'
                            name='password'
                            className='form-control'
                            placeholder='Password'
                            maxLength={255}
                            onChange={helpers.handleChange}
                            onBlur={helpers.handleBlur}
                            value={helpers.values.password}
                            disabled={helpers.isSubmitting}
                          />
                          {helpers.touched.password && helpers.errors.password && (
                            <p className='error'>{helpers.errors.password}</p>
                          )}
                        </div>
                    
                        {helpers.errors.submit && (
                          <p className='error'>{helpers.errors.submit}</p>
                        )}

                        <div className='btn-row'>
                          <button type='button' className='btn--chromeless' onClick={() => setShowResetPassword(true)}>
                            Forgot Password?
                          </button>
                          <button type='submit' className='btn landmark' disabled={helpers.isSubmitting}>
                            {helpers.isSubmitting ? <AnimatedEllipsis /> : 'Log In' }
                          </button>
                        </div>
                        <hr className='landmarklet' />
                        <p className='small'>
                          Don't have an account?
                          {' '}
                          <Link href={Routes.ONBOARDING_SIGNUP}>
                            Create New Account
                          </Link>
                        </p>
                      </form>
                    )
                  }}
                </Formik>
              )}
            </div>
         </div>
          <div className='g lap-one-half'>
            <div className='signin-half'></div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

const validateInput = (values:FormProps) => {
  const errors:StringDictionary = {}
  if (!values.email) {
    errors.email = 'Required'
  }
  else if (!isValidEmailAddress(values.email)) {
    errors.email = 'Must be a valid email address'
  }

  if (!values.password) {
    errors.password = 'Required'
  }

  return errors
}

const getUserLandingPage = (status:CompanyStatus):string => {
  switch (status) {
    case CompanyStatus.CompanyInfoRequired:
      return Routes.ONBOARDING_COMPANY_INFO
    case CompanyStatus.FinancialsRequired:
      return Routes.ONBOARDING_CONNECT_FINANCIALS
    case CompanyStatus.ApplicationPending:
    case CompanyStatus.ApplicationHeld:
    case CompanyStatus.WaitingForSignature:
      return Routes.ONBOARDING_APPLICATION_STATUS
    case CompanyStatus.Active:
    case CompanyStatus.FundingRequestPending:
    case CompanyStatus.Inactive:
      return Routes.SECURE_DASHBOARD
    default:
      // Any unpredicted status should send the user back to the login page.
      return Routes.LOGIN
  }
}